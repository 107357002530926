import React from 'react'
import { has, isUndefined} from 'lodash';
import T from 'types'
import './styles.sass';
import { VisioBoxWithSetup } from 'components';

type Props = {
  consultation: T.Consultation
  show: boolean
}

const TeleconsultationContentVisio = ( {
  consultation,
  show
 } : Props) => {

  const { visioSession } = consultation

  if (isUndefined(visioSession)) {
    console.error('Visio cannot be rendered: visioSession has not been provided')
    return null
  }

  if (!has(visioSession, 'session') || !has(visioSession, 'tokens')) {
    console.error('Visio cannot be rendered: session or tokens is missing in visioSession')
    return null
  }

  const {
    session: { apiKey, sessionId },
    tokens: { patient },
  } = visioSession

  return show ? (
    <VisioBoxWithSetup
      apiKey={apiKey}
      sessionId={sessionId}
      token={patient}
      consultationId={consultation.id}
    />
  ) : null
}

export default TeleconsultationContentVisio