export const SIMPLE_CONSULTATION_PRICE = 35
export const COMPLEX_CONSULTATION_PRICE = 70

export const PURCHASE_PRESCRIPTION_PRICE = 5

export const NUMBER_OF_AVAIBILITY_DAYS_DISPLAYED = 30

export const INVALID_PAYMENT_METHOD = { invalid: -1 }

export const TARGET_PLATFORM = process.env.TARGET_PLATFORM || 'charles'
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const IS_CHARLES_PLATFORM = TARGET_PLATFORM != 'mia'
export const SUPPORT_PHONE_NUMBER = IS_CHARLES_PLATFORM ? '01 86 65 17 33' : '01 86 65 18 91'
