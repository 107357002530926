import * as A from 'constants/actions'

import tracker from 'utils/trackers'
import { includes } from 'lodash'

const trackersEventsMap = {
  [A.SET_CHOSEN_CONSULTATION_TYPE]: (_, store) => {
    tracker.trackChoseConsultationType(
      store.getState().onboarding.consultationType,
      store.getState().onboarding.medium,
    )
  },
  [A.SET_CHOSEN_MEDIUM]: (_, store) => {
    tracker.trackChoseMedium(
      store.getState().onboarding.consultationType,
      store.getState().onboarding.medium,
    )
  },
  [A.SET_CHOSEN_DOCTOR]: (_, store) => {
    tracker.trackChoseDoctor(
      store.getState().onboarding.consultationType,
      store.getState().onboarding.medium,
    )
  },
  [A.SET_CONSULTATION]: (_, store) => {
    tracker.trackCreatedConsultation(
      store.getState().onboarding.consultationType,
      store.getState().onboarding.medium,
    )
  },
  [A.DID_START_PRE_PAYMENT_SURVEY]: (_, store) => {
    const didPreviouslyStartPrePaymentSurvey = store.getState().events.didStartPrePaymentSurvey
    if (!didPreviouslyStartPrePaymentSurvey) {
      tracker.trackDidStartPrePaymentSurvey()
}
  },
}

const INCLUDED_ACTIONS = Object.keys(trackersEventsMap)
const analyticsMiddleware = store => next => action => {
  if (includes(INCLUDED_ACTIONS, action.type)) {
    trackersEventsMap[action.type](action.payload, store)
  }
  return next(action)
}

export default analyticsMiddleware
