import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import T from 'types'
import { capitalize } from 'lodash'
import { SvgIcon, Button } from 'components'
import { getIcon } from 'assets/icons'
import moment from 'constants/moment'
import cx from 'classnames'
import './styles.sass'
import { RouteComponentProps, withRouter } from 'react-router'

interface IProps extends RouteComponentProps<any>{
  onClick: (p: T.Prescription) => void
  prescription: T.Prescription,
}

interface IState {
  prescriptionLimiter: number
}

class PrescriptionCard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      prescriptionLimiter: 2,
    }
  }

  private moleculeName(molecule : string) {
    return capitalize(I18n.t(`molecule.${molecule}`))
  }

  public render() {
    const { prescription } = this.props
    const expired = !prescription.canBeReleased && moment(prescription.expiredAt).diff(moment()) < 0

    return (
      <div className="home-page-card block-content d-flex flex-column prescription-card">
        <h5 className="prescription-card-title mb-4">Ordonnance : {I18n.t(`prescriptions.type.${prescription.prescriptionType}`)}</h5>
        <div className={cx('prescription-card-origin d-flex align-items-center mb-3', { 'prescription-card-expired': expired })}>
          <div className="prescription-card-origin-icon mr-4">
            <SvgIcon icon={getIcon('prescription')} />
          </div>
          <div>
            <h5 className="m-0">{prescription.doctor}</h5>
            <div className="mb-2">{prescription.doctorSpecialty}</div>
            <div>
              <u>
                valide du {moment(prescription.createdAt).format('L')} au {moment(prescription.expiredAt).format('L')}
              </u>
            </div>
          </div>
        </div>
        <ul className={cx('home-page-card-list prescription-card-list', { 'prescription-card-expired': expired })}>
          {prescription.prescriptionItems.slice(0, this.state.prescriptionLimiter).map((prescriptionItem: T.PrescriptionItem, index: number) => (
            <li key={`prescription_item_${index}`} className="prescription-card-item mb-3">
              <SvgIcon icon={getIcon('clinic.pillsBottle')} />
              {`${this.moleculeName(prescriptionItem.molecule)} ${prescriptionItem.grammage}`}<br />
              <span className="prescription-card-item-dosage">{I18n.t(`dosage.${prescriptionItem.dosage}`)}</span>
            </li>
          ))}
          {(prescription.prescriptionItems.length > 2 && this.state.prescriptionLimiter !== prescription.prescriptionItems.length) && (
            <div className="prescription-card-item-show-more" onClick={() => this.setState({ prescriptionLimiter: prescription.prescriptionItems.length })}>
              <SvgIcon icon={getIcon('clinic.plusIcon')} />
              <div>Voir plus</div>
            </div>
          )}
        </ul>
        {this.renderBtn()}
      </div>
    )
  }

  private renderBtn() {
    const { onClick, prescription, history } = this.props
    const expireAt = moment(prescription.expiredAt)
    const exhausted = !prescription.canBeReleased
    const expired = !prescription.canBeReleased && expireAt.diff(moment()) <= 0
    const clickFunction = expired
      ? () => history.push('/new-consultation/specialite')
      : () => onClick(prescription)
    const disabled = expired
      ? false
      : exhausted
    const buttonLabel = expired
      ? 'Consulter pour renouveler'
      : exhausted
        ? 'Commande maximale mensuelle atteinte'
        : 'Me faire livrer'

    return (
      <>
        {expired && (<div className="expiry-label">Votre ordonnance est expirée.</div>)}
        <Button disabled={disabled} onClick={clickFunction} classname="btn-block mt-auto">
          {buttonLabel}
        </Button>
      </>
    )
  }
}

export default withRouter(PrescriptionCard)
