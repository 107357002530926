import React, { Component, Fragment } from 'react'
import { map } from 'lodash'
import { Table } from '../..'
import {
  formatedCollection,
} from 'utils/helpers'
import T from 'types'
import moment from 'constants/moment'
import { I18n } from 'react-redux-i18n'
import SvgIcon from 'components/SvgIcon'
import calendar from 'assets/icons/clinic/32/calendar.svg'
import treatment from 'assets/icons/clinic/32/icon-small-traitement-perso-square.svg'
import '../styles.sass'
import './styles.sass'

interface IProps {
  prescription: T.Prescription,
}

export default class AnyPrescriptionContent extends Component<IProps> {

  private renderMedication() {
    const { prescription } = this.props

    return (
      <div>
        <div className="treatment-title">
          <SvgIcon icon={treatment} classname="pharma-icon mr-2" />
          <span>Médicaments</span>
        </div>
        <ul className="prescription-items">
          { map(prescription.prescriptionItems, (item, i) =>
            <li key={`${item.molecule}-${i}`}>
              <span className="molecule-details">
                {I18n.t(`molecule.${item.molecule}`)}, {item.grammage} : {I18n.t(`dosage.${item.dosage}`)}
              </span>
            </li>
          )}
        </ul>
      </div>
    )
  }

  public render() {
    const { prescription } = this.props

    return (
      <Fragment>
        <div className="prescription-header">
          <div className="header-item">
            <img className="doctor-image mr-2" src={prescription.doctorAvatarUrl} alt={prescription.doctor} />
          </div>
          <div className="header-item doctor-infos">
            <strong>{prescription.doctor}</strong>
            <div className="doctor-specialty">{prescription.doctorSpecialty || 'Praticien'}</div>
            <div className="expiry-ordo d-md-none">
              <SvgIcon icon={calendar} classname="pharma-icon mr-2" />
              Expire le {moment(prescription.expiredAt).format('L')}
            </div>
          </div>
          <div className="header-item prescription-expiry d-none d-md-block">
            <SvgIcon icon={calendar} classname="pharma-icon mr-2" />
            <span>Expire le {moment(prescription.expiredAt).format('L')}</span>
          </div>
        </div>
        {this.renderMedication()}
      </Fragment>
    )
  }
}
